import http from "../http-common";
import AuthService from "./AuthService";

export class DataService {
  protected url: string;

  constructor(url: string) {
    this.url = url;
  }

  getAll(page: number = 0, size: number = 0, sort: string = "") {
    if (size === 0) {
      return http.get(`${this.url}`,
      { headers: { Authorization: AuthService.token() } });
    } else {
      return http.get(`${this.url}?page=${page}&size=${size}&sort=${sort}`,
      { headers: { Authorization: AuthService.token() } });
    }
  }

  all(sort: string = "") {
      return http.get(`${this.url}`,
      { headers: { Authorization: AuthService.token() }, params: { sort: sort } });
  }

  get(id: string) {
    return http.get(`${this.url}/${id}`,
    { headers: { Authorization: AuthService.token() } });
  }

  count() {
    return http.get(`${this.url}/count`,
    { headers: { Authorization: AuthService.token() } });
  }

  create(data: any) {
    return http.post(`${this.url}`, data,
    { headers: { Authorization: AuthService.token() } });
  }

  update(id: string, data: any) {
    return http.put(`${this.url}/${id}`, data,
    { headers: { Authorization: AuthService.token() } });
  }

  delete(id: string) {
    return http.delete(`${this.url}/${id}`,
    { headers: { Authorization: AuthService.token() } });
  }

  findByTitle(title: string, page: number = 0, size: number = 0, sort: string = "", params: any = {}) {
    if (size === 0) {
      return http.get(`${this.url}`,
        {
          headers: { Authorization: AuthService.token() },
          params: Object.assign({ search: title, location }, params),
        });
    } else {
      return http.get(`${this.url}`,
        {
          headers: { Authorization: AuthService.token() },
          params: Object.assign({ search: title, location, page: page, size: size, sort: sort }, params),
        });
    }
  }

}
