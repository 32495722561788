var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md"},[_c('b-input-group',[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"search","placeholder":"Suche","debounce":"500"},model:{value:(_vm.searchtext),callback:function ($$v) {_vm.searchtext=$$v},expression:"searchtext"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.search}},[_c('b-icon',{attrs:{"icon":"search","variant":"outline-primary"}}),_vm._v(" Suche")],1)],1)],1)],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md"},[_c('b-table',{attrs:{"id":"results","striped":"","hover":"","outlined":"","small":"","show-empty":"","selectable":"","select-mode":"single","items":_vm.results,"fields":[
          { key: 'event', label: 'Termin' },
          {
            key: 'registrations',
            label: 'Gäste/Mitarbeiter',
            class: 'text-center',
          },
          { key: 'name', label: 'Namen' },
          { key: 'action', label: '' } ],"primary-key":"uuid","responsive":"sm"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',[_vm._v("Bitte Name im Suchfeld eingeben")])]},proxy:true},{key:"cell(event)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("moment")(data.item.date,"DD.MM.YYYY"))+" "+_vm._s(data.item.event.startTime)),_c('br'),_vm._v(" "+_vm._s(data.item.event.name)+", "+_vm._s(data.item.event.location.name)+" ")]}},{key:"cell(registrations)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.guests(data.item))+"/"+_vm._s(_vm.staff(data.item))+" ")])]}},{key:"cell(name)",fn:function(data){return _vm._l((_vm.names(data.item)),function(guest,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(guest.lastName)+", "+_vm._s(guest.firstName)+" ")])})}},{key:"cell(action)",fn:function(data){return [_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.download(data.item)}}},[_vm._v("Registrierungen")])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md"},[_c('h4',[_vm._v("Suche")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md"},[_c('div',{staticClass:"small"},[_vm._v(" Die Suche ermittelt alle Veranstaltungen, an denen ein Besucher teilgenommen hat. Geben Sie Nachname und/oder Vorname ein. ")])])])}]

export { render, staticRenderFns }