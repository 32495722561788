import http from "../http-common";
import AuthService from "./AuthService";
import { DataService } from "./DataService";

class EventService extends DataService {

  getEvent(event: string, date: string) {
    return http.get(`${this.url}/view/${event}`, { headers: { Authorization: AuthService.token() }, params: { date: date } });
  }

  checkin(registration: string, guest: string, checkin: boolean) {
    return http.post(`${this.url}/checkin/${registration}/${guest}`, registration, { headers: { Authorization: AuthService.token() }, params: { checkin: checkin } });
  }

  remove(uuid: string) {
    return http.delete(`${this.url}/remove/${uuid}`, { headers: { Authorization: AuthService.token() } });
  }

  send(uuid: string) {
    return http.post(`${this.url}/send/${uuid}`, {}, { headers: { Authorization: AuthService.token() } });
  }

  saveItem(item: any) {
    return http.post(`${this.url}/item/${item.uuid}`, item, { headers: { Authorization: AuthService.token() } });
  }

  createRegistration(registration: any) {
    return http.post(`${this.url}/create`, registration, { headers: { Authorization: AuthService.token() } });
  }

  updateRegistration(registration: any) {
    return http.put(`${this.url}/update/${registration.uuid}`, registration, { headers: { Authorization: AuthService.token() } });
  }

  download(uuid: string, date: string, template: string) {
    return http.request({
      method: 'get',
      url: `${this.url}/download/${uuid}`,
      headers: { Authorization: AuthService.token() },
      params: { date: date, template: template },
      responseType: 'blob'
    });
  }

  searchRegistrations(search: string) {
    return http.get(`${this.url}/search`, { headers: { Authorization: AuthService.token() }, params: { search: search } });
  }

  searchGuests(search: string) {
    return http.get(`${this.url}/guests`, { headers: { Authorization: AuthService.token() }, params: { search: search } });
  }

  verify(uuid: string) {
    return http.get(`${this.url}/verify/${uuid}`, { headers: { Authorization: AuthService.token() } });
  }

  statistics() {
    return http.get(`${this.url}/statistics`, { headers: { Authorization: AuthService.token() } });
  }

}

export default new EventService('/event');