

























































































import EventService from "@/services/EventService";
import moment from "moment";
import { Component, Vue, Watch } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class Search extends Vue {
  private searchtext: string = "";
  private results: any[] = [];

  @Watch("searchtext")
  search() {
    if (this.searchtext) {
      EventService.searchGuests(this.searchtext).then(
        (response) => (this.results = response.data)
      );
    } else {
      this.results = [];
    }
  }

  download(item: any) {
    EventService.download(item.event.uuid, item.date, "guestlist").then(
      (response: any) => {
        var downloadURL = URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const anchor = document.createElement("a");
        anchor.download = `${moment(item.date).format(
          "YYMMDD"
        )}-${item.event.startTime.replace(":", "")}-${
          item.event.location.name
        }-${item.event.name}-Registrierungen.pdf`;
        anchor.href = downloadURL;
        anchor.target = "_blank";
        anchor.click();
      }
    );
  }

  names(item: any) {
    let list: any[] = [];
    let substrings: string[] = this.searchtext.toLowerCase().split(" ");

    item.registrations.forEach((r: any) =>
      r.guests
        .filter((g: any) =>
          substrings.some((v) =>
            (g.firstName + g.lastName).toLowerCase().includes(v)
          )
        )
        .forEach((g: any) => list.push(g))
    );

    return list;
  }

  guests(item: any) {
    return item.registrations.reduce(
      (a: any, b: any) => a + (b.guests.length || 0),
      0
    );
  }

  staff(item: any) {
    return item.staff.length;
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);
  }
}
